<template>
  <div class="leftStateWrap">
    <template v-if="data.st == 5">
      <div class="col1">
        <span v-if="data.clk && data.clk.pe">
          {{ match_period[data.clk.pe][getLocal()] }}</span
        >
      </div>
      <div class="time col2">
        <span v-if="checkClkState()">{{ secTotime(timeNum) }}'</span>
      </div>
    </template>
    <div v-else class="col1">{{ text }}</div>
    <div v-if="!data.st || data.st == 4" class="col2">{{ time }}</div>
  </div>
</template>

<script>
import { tygList, stList, match_period } from "@/utils/filedList";
import { Format } from "@/utils/fn";
export default {
  name: "SgsLeft",
  props: ["data"],
  data() {
    return {
      tygList,

      timeNum: 0,
      num: 1,
      timer: null,
      match_period,
    };
  },
  computed: {
   
    sgsData() {
      if (!this.data.sgs) return [];
      const mapTemp = [];
      let clkPe = this.data.clk ? this.data.clk.pe : 0;
      this.data.sgs.forEach((item) => {
        let isHt =
          clkPe >= 1006 && clkPe <= 1009
            ? item.pe == 1004 && item.tyg == 5
            : item.pe == 1002 && item.tyg == 5;
        if (isHt) {
          mapTemp.push(this.$t("home.HT") + `:${item.sc.join("-")}`);
        }
        if (item.pe == 1000 && item.tyg == 6) {
          mapTemp.push(this.$t("home.Corner") + `:${item.sc.join("-")}`);
        }
      });
      return mapTemp.reverse();
    },
    text() {
      return stList[this.data.st === undefined ? 4 : this.data.st][
        this.getLocal()
      ];
    },
    time() {
      return Format(new Date(this.data.btm), "yyyy/M/d");
    },
  },
  created() {
    if (this.data.st == 5) {
      this.startTime();
    }
  },
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  },
  watch: {
    "data.clk.s": {
      handler() {
        this.startTime();
      },
    },
    "data.clk.r": {
      handler() {
        this.startTime();
      },
    },
  },
  methods: {
    checkClkState() {
      const notSt = [
        1001, 1005, 1009, 1010, 1011, 1012, 1013, 1014, 1015, 1016,
      ];

      if (!this.data.clk || !this.data.clk.pe) return false;
      if (notSt.includes(this.data.clk.pe)) return false;
      if (
        this.data.clk.s === undefined ||
        this.data.clk.s === null ||
        this.data.clk.s === ""
      )
        return false;

      return true;
    },
    startTime() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      if (!this.checkClkState()) {
        return;
      }
      this.timeNum = this.data.clk.s;

      if (this.data.clk.tp == 1) {
        this.num = 1;
      } else {
        this.num = -1;
      }
      if(this.data.clk.r){
        this.timer = setInterval(() => {
          this.timeNum += this.num;
          if (this.data.clk.tp != 1 && this.timeNum == 0) {
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    secTotime(s) {
      var t = "00:00";
      if (s > -1) {
        t = '';
        var min = Math.floor(s / 60);
        var sec = s % 60;

        if (min < 10) {
          t += "0";
        }
        t += min + ":";
        if (sec < 10) {
          t += "0";
        }
        t += sec;
      }
      return t;
    },
  },
};
</script>

<style lang="scss" scoped>
.leftStateWrap {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  flex-wrap: nowrap;
  overflow: hidden;
  flex: 1;
  font-weight: 400;
  font-size: 13px;
  .col1 {
    width: 105px;
    overflow: hidden;
    margin-right: 4px;
      white-space: nowrap;
  }
  .col2 {
    width: 85px;
    margin-right: 4px;
    overflow: hidden;
    font-weight: 500;

  }
  .col3 {
    flex: 1;
    text-align: left;
    margin-right: 4px;
    overflow: hidden;
  }
  
  .time {
    color: #EA3443;
    font-size: 13px;
  }
}
</style>