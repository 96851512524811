<template>
  <div class="dateTab">
    <div class="left" v-if="dateData.length >= 9" @click="sceollHandle(false)">
      <i class="el-icon-caret-left"></i>
    </div>
    <div class="content" ref="content">
      <div class="contentFlex">
        <div
          :class="['item', 'allItem', { active: active == 'all' }]"
          @click="$emit('update:active', 'all')"
        >
          {{ $t("home.All") }}
        </div>
        <div
          v-for="(item, index) in dateData"
          :key="index"
          :class="['item', { active: active == item.dateTime }]"
          @click="$emit('update:active', item.dateTime)"
        >
          <div>{{ item.formatWeek }}</div>
          <div class="center">
            {{ getMonth(item.btm) + $t("home.tabM")
            }}<span>{{ getDay(item.btm) }}</span
            >{{ $t("home.tabD") }}
          </div>
          <div>{{ getRankNum(item.groupValueArr) + $t('home.rankNum')}}</div>
        </div>
      </div>
    </div>
    <div class="right" v-if="dateData.length >= 9" @click="sceollHandle(true)">
      <i class="el-icon-caret-right"></i>
    </div>
  </div>
</template>

<script>
import { Format } from "@/utils/fn";
import { stageGroup } from "@/utils/filedList";

export default {
  name: "DateTab",
  props: ["dateData", "active", "scheduleModal", "menuActive"],
  methods: {
    sceollHandle(right) {
      let scrollLeft = this.$refs.content.scrollLeft + (right ? 97 : -97);
      this.$refs.content.scrollTo(scrollLeft, 0);
    },
    getMonth(btm) {
      return Format(new Date(btm), `M`);
    },
    getDay(btm) {
      return Format(new Date(btm), `d`);
    },
    getRankNum(groupValueArr){
      let _menuActive = this.menuActive+'';
      if(_menuActive == 'all'){
        return groupValueArr.length
      }
      let idx = _menuActive.indexOf("all");
      if (idx > -1) {
        let activeId = _menuActive.replace("all", "");
        let stageGroupText = stageGroup[activeId].group.join(',')
        return groupValueArr.filter(item => stageGroupText.includes(item)).length
      }
      return groupValueArr.filter(item => item == _menuActive).length
    }
  },
  mounted() {
    console.log(this.scheduleModal);
  },
};
</script>

<style lang="scss" scoped>
.dateTab {
  display: flex;
  overflow: hidden;
  border: 1px solid $border-color;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.80);
  backdrop-filter: blur(10px);
  .left,
  .right {
    height: 80px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: #0238E0;
    }
  }
  .content {
    flex: 1;
    // margin: 0 8px;
    display: flex;
    overflow: hidden;
    .contentFlex {
      display: flex;
    }
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      // align-items: center;
      width: 100px;
      height: 80px;
      border-right: 1px solid $border-color;
      // border-bottom: 1px solid $border-color;
      // background: $tab-bg-color;
      padding: 9px 12px;
      cursor: pointer;
      color: #5A6375;
      div{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &:last-child {
        margin-right: 0;
      }
      .center {
        display: flex;
        align-items: baseline;
        color: $text-color;
        margin-top: 4px;
      }
      span {
        font-size: 22px;
        font-family: "DIN";
        font-style: normal;
        font-weight: 700;
        padding: 0 2px;
      }
    }
    .allItem{
      align-items: center;
      font-weight: 500;
    }
    .active {
      background: #0238E0;
      // box-shadow: 0px 4px 10px rgba(175, 28, 59, 0.2);
      color: #fff;
      .center {
        color: #fff;
      }
    }
  }
}
.dark{
  .dateTab{
    background: #151618;
  }
}
</style>