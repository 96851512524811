<template>
  <div class="sgsCenter">
    <div :class="['rank']">
      <div class="red" v-if="redData.length && redData[0] > 0">{{ redData[0] }}</div>
      <div class="yellow" v-if="yellowData.length && yellowData[0] > 0">{{ yellowData[0] }}</div>
      <div :class="['tms', { low: leftLow }]">
        {{ data.tms[0] && data.tms[0].na }}
      </div>
      <img
        v-if="data.tms[0] && data.tms[0].lurl"
        :src="data.tms[0].lurl"
        alt=""
      />
      <img v-else :src="require('@/assets/imgs/comm/outDefault.svg')" alt="" />
    </div>
    <div :class="['score']">
      <template v-if="data.st == 4 || data.st === undefined">
        <div class="waitWrap">
          <span>{{ time }}</span>
          <img
            :src="require(`@/assets/imgs/schedule/waitTime.svg`)"
            class="waitTime"
            alt=""
          />
        </div>
      </template>
      <template v-else>
        <span :class="{ low: leftLow }">{{ scoreData[0] }}</span>
        <span class="line">-</span>
        <span :class="{ low: rightLow }">{{ scoreData[1] }}</span>
      </template>
    </div>
    <div :class="['rank']">
      <img
        v-if="data.tms[1] && data.tms[1].lurl"
        :src="data.tms[1].lurl"
        alt=""
      />
      <img v-else :src="require('@/assets/imgs/comm/outDefault.svg')" alt="" />
      <div :class="['tms', { low: rightLow }]">
        {{ data.tms[1] && data.tms[1].na }}
      </div>
      <div class="red" v-if="redData.length && redData[1] > 0">{{ redData[1] }}</div>
      <div class="yellow" v-if="yellowData.length && yellowData[1] > 0">{{ yellowData[1] }}</div>
    </div>
  </div>
</template>

<script>
import { Format } from "@/utils/fn";

export default {
  name: "SgsCenter",
  props: ["data"],
  computed: {
    time() {
      return Format(new Date(this.data.btm), "hh:mm");
    },
    scoreData() {
      if (!this.data.sgs) return [0, 0];
      const _data = this.data.sgs.reduce((prev, item) => {
        if ((item.pe == 1001 || item.pe == 1013) && item.tyg == 5) {
          prev = prev.length > 0 ? prev : [0, 0];
          prev[0] = prev[0] + item.sc[0];
          prev[1] = prev[1] + item.sc[1];
        }
        return prev;
      }, []);
      return _data;
    },
    redData() {
      if (!this.data.sgs) return [];
      const _data = this.data.sgs.reduce((prev, item) => {
        if ((item.pe == 1001 || item.pe == 1013) && item.tyg == 8) {
          prev = prev.length > 0 ? prev : [0, 0];
          prev[0] = prev[0] + item.sc[0];
          prev[1] = prev[1] + item.sc[1];
        }
        return prev;
      }, []);
      return _data;
    },
    yellowData() {
      if (!this.data.sgs) return [];
      const _data = this.data.sgs.reduce((prev, item) => {
        if ((item.pe == 1001 || item.pe == 1013) && item.tyg == 7) {
          prev = prev.length > 0 ? prev : [0, 0];
          prev[0] = prev[0] + item.sc[0];
          prev[1] = prev[1] + item.sc[1];
        }
        return prev;
      }, []);
      return _data;
    },
    leftLow() {
      if (this.data.st == 4 || this.data.st === undefined) {
        return false;
      }
      return this.scoreData[0] < this.scoreData[1];
    },
    rightLow() {
      if (this.data.st == 4 || this.data.st === undefined) {
        return false;
      }
      return this.scoreData[0] > this.scoreData[1];
    },
  },
};
</script>

<style lang="scss" scoped>
.sgsCenter {
  width: 408px;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-right: 27px;
  // margin-left: 20px;
  overflow: hidden;
  font-weight: 500;
  & > * {
    // margin-right: 8px;
    white-space: nowrap;
  }
  .waitTime {
    width: 20px;
    height: 20px;
  }
  .red,
  .yellow {
    padding: 0 2px;
    color: #fff;
    background-color: #ea3443;
    height: 16px;
    width: 12px;
    text-align: center;
    line-height: 15px;
    font-size: 12px;
    border-radius: 3px;
    margin-right: 2px;
    display: block;
    transform: scale(0.8);
  }
  .score {
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 4px;
    width: 78px;
    font-family: "DIN";
    font-style: normal;
    font-weight: 700;
    .line {
      margin: 0 2px;
    }
    .waitWrap {
      position: relative;
      height: 55px;
      line-height: 55px;
      span {
        position: absolute;
        color: #8d9198;
        font-size: 13px;
        font-weight: 500;
        top: 0px;
        line-height: initial;
        left: 50%;
        transform: translate(-50%, 0);
        font-family: "PingFang SC";
      }
    }
  }
  .yellow {
    background-color: #ffba07;
  }
  // .red+.yellow{
  //   margin-left: 4px;
  // }

  .rank {
    flex: 1;
    overflow: hidden;
    display: flex;
    justify-content: right;
    align-items: center;

    .tms {
      font-size: 14px;
    }
    // & > * {
    //   margin-right: 8px;
    //   &:last-child {
    //     margin-right: 0;
    //   }
    // }
    img {
      width: 40px;
      height: 40px;
      margin-left: 8px;
    }
    &:last-child {
      img {
        margin-left: 0;
        margin-right: 8px;
      }
      .yellow,
      .red {
        margin-right: 0;
        margin-left: 2px;
      }
      justify-content: left;
    }
  }
  .low {
    color: $text-color-light !important;
    opacity: 0.8;
  }
}
</style>